<template>
  <div>
    <!--顶部工具条-->
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item label="名称:">
          <el-input v-model="filters.name" placeholder="请输入关键字" clearable @clear='clearContent' @input='() =>(filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='getDataPermissions'></el-input>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table :data="dataPer" highlight-current-row 
    @current-change="selectCurrentRow"
    v-loading="listLoading" @selection-change="selsChange"
                style="width: 100%;">
        <!-- <el-table-column type="selection" width="50">
        </el-table-column> -->
        <el-table-column type="index" width="80">
        </el-table-column>
        <el-table-column prop="Name" label="数据权限名称" width="" sortable>
        </el-table-column>
        <el-table-column prop="Code" label="数据权限编码" width="" sortable>
        </el-table-column>
        <el-table-column prop="Remarks" label="备注" width="" sortable>
        </el-table-column>
    </el-table>

    <!--新增-->
    <el-dialog title="新增" :visible.sync="addFormVisible" v-model="addFormVisible" :close-on-click-modal="false">
        <el-form :model="addForm" label-width="120px" :rules="addFormRules" ref="addForm">
            <el-form-item label="数据权限名称:" prop="Name">
                <el-input v-model="addForm.Name" auto-complete="off" @input='() =>(addForm.Name = addForm.Name.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label="数据权限编码:" prop="Code">
                <el-input v-model="addForm.Code" auto-complete="off" @input='() =>(addForm.Code = addForm.Code.replace(/\s/g,""))'></el-input>
            </el-form-item>


            <el-form-item label="数据权限类型:">
              <el-checkbox-group v-model="addType"> 
                <el-checkbox label="全部" name='Type'></el-checkbox>
                <el-checkbox label="本部" name='Type'></el-checkbox>
                <el-checkbox label="本部及以下" name='Type'></el-checkbox>
                <el-checkbox label="本人" name='Type'></el-checkbox>
                <el-checkbox label="无" disabled name='Type'></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
           


            <el-form-item label="备注:">
                <el-input type="textarea" v-model="addForm.Remarks"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click.native="addFormVisible = false">取消</el-button>
            <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
        </div>
    </el-dialog>
    <!--编辑-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" v-model="editFormVisible" :close-on-click-modal="false">
        <el-form :model="editForm" label-width="120px" :rules="editFormRules" ref="editForm">
            <el-form-item label="数据权限名称:" prop="Name">
                <el-input v-model="editForm.Name" auto-complete="off" @input='() =>(editForm.Name = editForm.Name.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label="数据权限编码:" prop="Code">
                <el-input v-model="editForm.Code" auto-complete="off" @input='() =>(editForm.Code = editForm.Code.replace(/\s/g,""))'></el-input>
            </el-form-item>

            <el-form-item label="数据权限类型:">
              <el-checkbox-group v-model="editType"> 
                <el-checkbox label="全部" name='Type'></el-checkbox>
                <el-checkbox label="本部" name='Type'></el-checkbox>
                <el-checkbox label="本部及以下" name='Type'></el-checkbox>
                <el-checkbox label="本人" name='Type'></el-checkbox>
                <el-checkbox label="无" disabled name='Type'></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item label="备注:">
                <el-input type="textarea" v-model="editForm.Remarks"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click.native="editFormVisible = false">取消</el-button>
            <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import util from '../../../util/date';
import {getAllDataPermissionsInfoList,updateDataPermissionsInfo, addDataPermissionsInfo,deleteDataPermissionsInfo,} from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
  components: { Toolbar },
  data() {
    return {
      dataPer:[],
      listLoading: false,
      currentRow: null,
      //列表选中列
      sels: [],
      //增删改查按钮
      buttonList: [],
      //顶部筛选条件
      filters: { 
          name: ''
      },
      //新增界面是否显示
      addFormVisible: false,
      addLoading: false,
      addFormRules: {
        Name: [
            {required: true, message: '请输入数据权限名称', trigger: 'blur'}
        ],
        Code: [
            {required: true, message: '请输入数据权限编码', trigger: 'blur'}
        ],
        Type: [
          {required: true, message:'请选择数据权限类型', trigger:'change'}        
        ]
      },
      //dialog数据
      addForm: {  
        Name: '',
        Code: '',
        ID:'',
      },
      addType:['无'],   //新增数据权限类型
      //编辑界面是否显示
      editFormVisible: false,
      editLoading: false,
      editFormRules: {
        Name: [
            {required: true, message: '请输入数据权限名称', trigger: 'blur'}
        ],
        Code: [
            {required: true, message: '请输入数据权限编码', trigger: 'blur'}
        ],
        Type: [
            {required: true, message:'请选择数据权限类型', trigger:'change'}        
        ]
      },
      //dialog数据
      editForm: {  
        Name: '',
        Code: '',
        ID:'',
      },
      editType:["无"]  //编辑数据权限类型
    };
  },
  methods: {
    // 筛选框清空，重新获取数据
    clearContent(){
      this.getDataPermissions()
    },
    //当前行发生变化时
    selectCurrentRow(val) {
        this.currentRow = val;
    },
    //选项发生变化时
    selsChange: function (sels) {
        this.sels = sels;
    },
    //显示新增界面
    handleAdd(){
      this.addForm = {
        Name: '',
        Code: '',
        ID:JSON.parse(localStorage.getItem("user")).ID,
      };
      this.addType = ['无'],
      this.addFormVisible = true;
    },
    //显示编辑界面
    handleEdit(){
      let row = this.currentRow;
       this.editType = ["无"]
      if (!row) {
          this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
          });
          return;
      }
      this.editForm = Object.assign({}, row);
      if(!row.IsHideSelf){
        this.editType.push("本人")
      }
      if(!row.IsHideOlnydept){
        this.editType.push("本部")
      }
      if(!row.IsHideBelowdept){
        this.editType.push("本部及以下")
      }
      if(!row.IsHideAll){
        this.editType.push("全部")
      }
      this.editFormVisible = true;
    },
    //新增
    addSubmit: function () {
        this.$refs.addForm.validate((valid) => {
            if (valid && this.addType.length > 0) {
                this.$confirm('确认提交吗？', '提示', {}).then(() => {
                  this.addLoading = true;
                  var IsHideAllNum = 0
                  var IsHideOlnydeptNum = 0
                  var IsHideBelowdeptNum = 0
                  var IsHideSelfNum = 0
                  for(var i = 0;i<this.addType.length;i++){
                    if(this.addType[i] == "全部"){
                      IsHideAllNum++
                      this.addForm.IsHideAll = false
                    }else if(IsHideAllNum == 0){
                      this.addForm.IsHideAll = true
                      IsHideAllNum++
                    }
                    if(this.addType[i] == "本部"){
                        this.addForm.IsHideOlnydept = false
                        IsHideOlnydeptNum++
                    }else if(IsHideOlnydeptNum == 0){
                        this.addForm.IsHideOlnydept = true
                        IsHideOlnydeptNum++
                    }
                    if(this.addType[i] == "本部及以下"){
                        this.addForm.IsHideBelowdept = false
                        IsHideBelowdeptNum++
                    }else if(IsHideBelowdeptNum == 0){
                        this.addForm.IsHideBelowdept = true
                        IsHideBelowdeptNum++
                    }
                    if(this.addType[i] == "本人"){
                        this.addForm.IsHideSelf = false
                        IsHideSelfNum++
                    }else if(IsHideSelfNum == 0){
                        this.addForm.IsHideSelf = true
                        IsHideSelfNum++
                    }
                  }
                  let params = Object.assign({}, this.addForm);
                  addDataPermissionsInfo(params).then((res) => {
                    if (util.isEmt.format(res)) {
                      this.addLoading = false;
                      return;
                    }

                    if (res.data.Success) {
                      this.addLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'success'
                      });
                      this.addFormVisible = false;
                      this.getDataPermissions();
                    }
                    else {
                      this.addLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'error'
                      });

                    }
                  });
                });
            }else{
              this.$message({
                message: "请选择数据权限类型！",
                type: "error"
              });
            }
        });
    },
    //编辑
    editSubmit:function(){
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
              this.editLoading = true;
              var IsHideAllNum = 0
              var IsHideOlnydeptNum = 0
              var IsHideBelowdeptNum = 0
              var IsHideSelfNum = 0
              for(var i = 0;i<this.editType.length;i++){
                if(this.editType[i] == "全部"){
                  IsHideAllNum++
                  this.editForm.IsHideAll = false
                }else if(IsHideAllNum == 0){
                  this.editForm.IsHideAll = true
                  IsHideAllNum++
                }
                if(this.editType[i] == "本部"){
                    this.editForm.IsHideOlnydept = false
                    IsHideOlnydeptNum++
                }else if(IsHideOlnydeptNum == 0){
                    this.editForm.IsHideOlnydept = true
                    IsHideOlnydeptNum++
                }
                if(this.editType[i] == "本部及以下"){
                    this.editForm.IsHideBelowdept = false
                    IsHideBelowdeptNum++
                }else if(IsHideBelowdeptNum == 0){
                    this.editForm.IsHideBelowdept = true
                    IsHideBelowdeptNum++
                }
                if(this.editType[i] == "本人"){
                    this.editForm.IsHideSelf = false
                    IsHideSelfNum++
                }else if(IsHideSelfNum == 0){
                    this.editForm.IsHideSelf = true
                    IsHideSelfNum++
                }
              }
              let params = Object.assign({}, this.editForm); 
              updateDataPermissionsInfo(params).then((res) => {
                if (util.isEmt.format(res)) {
                  this.editLoading = false;
                  return;
                }
                if (res.data.Success) {
                  this.editLoading = false;
                  this.$message({
                      message: res.data.Message,
                      type: 'success'
                  });
                  this.editFormVisible = false;
                  this.getDataPermissions();
                }
                else {
                  this.editLoading = false;
                  this.$message({
                      message: res.data.Message,
                      type: 'error'
                  });

                }
              });
          });
        }
      })
    },
    //删除
    handleDel(){
      let row = this.currentRow;
      if (!row) {
          this.$message({
          message: "请选择要删除的一行数据！",
          type: "error"
          });

          return;
      }
      this.$confirm('确认删除该数据权限吗?', '提示', {
          type: 'warning'
      }).then(() => {
          this.listLoading = true;
          let params = {id: row.ID};
          deleteDataPermissionsInfo(Qs.stringify(params)).then((res) => {
              if (util.isEmt.format(res)) {
                this.listLoading = false;
                return;
              }

              if (res.data.Success) {
                this.listLoading = false;
                this.$message({
                    message: res.data.Message,
                    type: 'success'
                });
                this.userFormVisible = false;
                this.getDataPermissions();
              }
              else {
                this.listLoading = false;
                this.$message({
                    message: res.data.Message,
                    type: 'error'
                });

              }
          });
      }).catch(() => {

      });
    },
    //获取数据权限列表
    getDataPermissions(){
        this.listLoading = true;
        let params = {
          name: this.filters.name,
        };
        getAllDataPermissionsInfoList(params).then((res) => {
            this.dataPer = res.data.Response;
            this.listLoading = false;
        });
    },
    
    callFunction(item) {
      this[item.Func].apply(this, item);
    }
  },
  mounted() {
    this.getDataPermissions();
    let routers = window.localStorage.router
    ? JSON.parse(window.localStorage.router)
    : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  }
};
</script>
<style lang="stylus" scoped>
  .el-table>>>td, .el-table>>>th{
    padding: 8px!important;
  }
</style>
